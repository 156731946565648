import UserService from "./user-service";

// Définition des fonctions d'accès, de lecture et d'écriture pour différentes listes et fonctionnalités

const isAuthenticated = () => {
    return UserService.isAuthenticated();
}

const hasRequiredRole = (role) => {
    return UserService.hasRequiredRole(role);
}

const readListePrincipale = () => {
    return UserService.isRoleAdmin() || 
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte() || 
           UserService.isRolePointage() ||
           UserService.isRoleAttributionAdo() || 
           UserService.isRoleAttributionAdulte() ||
           UserService.isRoleCertification(); 
}

const writeListePrincipale = () => {
    return UserService.isRoleAdmin() || 
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const readListePerseverance = () => {
    return UserService.isRoleAdmin() || 
           UserService.isRolePointage() || 
           UserService.isRoleCoachPerseveranceAdo() ||
           UserService.isRoleCoachPerseveranceAdulte() ||
           UserService.isRoleAttributionAdo() || 
           UserService.isRoleAttributionAdulte();
}

const writeListePerseverance = () => {
    return UserService.isRoleAdmin() || 
           // UserService.isRoleCoachPerseveranceAdo() ||
           // UserService.isRoleCoachPerseveranceAdulte() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const readListeAttente = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const writeListeAttente = () => {
    return UserService.isRoleAdmin();
}

const readMesSaisies = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleSaisie();
}

const writeMesSaisies = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleSaisie();
}

const readPointage = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRolePointage();
}

const writePointage = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRolePointage();
}

const readAttribution = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const writeAttribution = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const readMaListeAppel = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte();
}

const writeMaListeAppel = () => {
    return UserService.isRoleAdmin();
}

const readCompteRendu = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte();
}

const writeCompteRendu = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte();
}

const readSuiviPerseverance = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachPerseveranceAdo() ||
           UserService.isRoleCoachPerseveranceAdulte();
}

const readSuiviPrincipale = () => {
    return UserService.isRoleAdmin();
}

const writeSuiviPerseverance = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachPerseveranceAdo() ||
           UserService.isRoleCoachPerseveranceAdulte();
}

const writeSuiviPrincipale = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte();
}

const readMessages = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte() ||
           UserService.isRolePointage() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte() ||
           UserService.isRoleCertification();
};

const readCertificat = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCertification();
}

const readFormations = () => {
    return UserService.isRoleAdmin() || UserService.isRoleFormation();
};

const readSuiviFormation = () => {
    return UserService.isRoleAdmin() || UserService.isRoleFormation();
};

const writeCertificat = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCertification();
}

const readFormation = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCertification();
}

const readMonSuivi = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleCoachAdo() ||
           UserService.isRoleCoachAdulte();
}

const readSuiviAction = () => {
    return UserService.isRoleAdmin() ||
           UserService.isRoleAttributionAdo() ||
           UserService.isRoleAttributionAdulte();
}

const writeFormation = () => {
    return UserService.isRoleAdmin();
}

const writeAdmin = () => {
    return UserService.isRoleAdmin();
}

// Exportation de l'objet AuthorizationService contenant toutes les fonctions de vérification des droits
const AuthorizationService = {
    isAuthenticated,
    hasRequiredRole,
    readListePrincipale,
    writeListePrincipale,
    readListePerseverance,
    writeListePerseverance,
    readListeAttente,
    writeListeAttente,
    readMesSaisies,
    writeMesSaisies,
    readPointage,
    writePointage,
    readAttribution,
    writeAttribution,
    readMaListeAppel,
    writeMaListeAppel,
    readCompteRendu,
    writeCompteRendu,
    readSuiviPerseverance,
    writeSuiviPerseverance,
    readSuiviAction,
    readMessages,
    readCertificat,
    readFormations,
    readSuiviFormation,
    writeCertificat,
    readFormation,
    readMonSuivi,
    writeFormation,
    writeAdmin,
    readSuiviPrincipale,
    writeSuiviPrincipale
};

export default AuthorizationService;
